import { Tenant, TenantBootstrapProps } from '@amzn/showtime';

import atozTenantId from './TenantId';
import mobileTenantConfig from './MobileTenantConfig';
import getPlacements from './placements';
import postRegister from './postRegister';

export default function bootstrapTenant(props: TenantBootstrapProps): Tenant {
    const { features } = props;

    const placements = getPlacements(features);

    return {
        atozTenantId,
        mobileTenantConfig,
        placements,
        postRegister,
    };
}
