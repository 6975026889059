import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AtoZMobileWeb, HTMLDataProps } from '@amzn/atozmobile-web';
import HelloWorld from '../src';

const devAuthToken = process.env.DEV_AUTH_TOKEN || '';

export default function App(props: HTMLDataProps) {
    const bundleVersion = process.env.BUNDLE_VERSION || 'unknown';

    return (
        <AtoZMobileWeb
            bootstrapFunctions={[HelloWorld]}
            bundleVersion={bundleVersion}
            devAuthToken={devAuthToken}
            htmlDataAttributes={props}
        />
    );
}
