import { IconAtozMenuLarge } from '@amzn/atoz-native';
import { Endpoints, PlacementType, SectionScreenPlacement } from '@amzn/showtime';

import HelloFullPage from './HelloFullPage';

export const id = 'page-hello';
const basePath = 'hello-world';
const uri = `${Endpoints.ATOZ}/${basePath}`;

const placement: SectionScreenPlacement = {
    component: HelloFullPage,
    id,
    rnwPath: basePath,
    uri,
    title: 'ADP Playground',
    type: PlacementType.SectionScreen,
    primaryNav: {
        id: `${id}-primary`,
        label: 'Playground',
        defaultStateIcon: IconAtozMenuLarge,
        activeStateIcon: IconAtozMenuLarge,
        priority: 400,
        uri,
    },
};

export default placement;
