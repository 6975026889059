import React from 'react';
import { View, Text } from 'react-native';
import styles from './Styles';
import { InstructionsProps } from './types';

export default function Instructions({ packageName }: InstructionsProps) {
    return (
        <View>
            <Text style={styles.instructionStep}>
                {`1. Add ${packageName} to your brazil workspace`}
            </Text>
            <Text style={styles.monospace}># In your workspace&apos;s root directory</Text>
            <Text style={styles.monospace}>{`brazil workspace use --package ${packageName}`}</Text>
            <Text style={styles.monospace}>{`cd src/${packageName}`}</Text>
            <Text style={styles.monospace}>brazil-build</Text>

            <Text style={styles.instructionStep}>
                2. Open a new terminal and start the Webpack development server
            </Text>
            <Text style={styles.monospace}>brazil-build web</Text>

            <Text style={styles.instructionStep}>
                3. Edit and save HelloFullPage.tsx and your changes will show up live.
            </Text>
        </View>
    );
}
