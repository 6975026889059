import { Placement } from '@amzn/showtime';
import HelloFullPage from './HelloFullPage';
import { hasPlaygroundFeature } from '../features';

const getPlacements = (features: string[]) => {
    const placements: Placement[] = [];

    if (hasPlaygroundFeature(features)) {
        placements.push(HelloFullPage);
    }

    return placements;
};

export default getPlacements;
