import type { MobileTenantConfig } from '@amzn/showtime';

const mobileTenantConfig: MobileTenantConfig = {
    awsAccountId: '742843212292',
    bindleId: 'amzn1.bindle.resource.h7pga2ooobmspghejffq',
    cti: {
        category: 'A to Z Employee Self Service',
        type: 'Quality Assurance',
        item: 'Showtime SDK',
    },
    emailList: 'a-to-z-qa-sdet-showtime@amazon.com',
    npmPackageName: '@amzn/atozmobile-tenant-atozqa-XPNWApp',
    posixGroup: 'a-to-z-qa-sdet',
    projectName: 'AtoZQAShowtimeXPNWApp',
    tenantId: 'AtoZXPNWTesting1',
};

export default mobileTenantConfig;
