import React from 'react';
import { View, StyleSheet } from 'react-native';
import { PlacementProps } from '@amzn/showtime';
import Instructions from './Instructions';
import Title from './Title';
import mobileTenantConfig from '../../MobileTenantConfig';

const { projectName: packageName } = mobileTenantConfig;

const styles = StyleSheet.create({
    h2: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    instructions: {
        backgroundColor: '#ffffff',
        margin: 8,
        padding: 8,
    },
    screenContainer: {
        backgroundColor: '#cccccc',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
    },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function HelloFullPage(props: PlacementProps) {
    console.log('HelloFullPage render');

    return (
        <View style={styles.screenContainer}>
            <View style={styles.instructions}>
                <Title style={styles.h2} />
                <Instructions packageName={packageName} />
            </View>
        </View>
    );
}
