import { Platform, StyleSheet } from 'react-native';

const monospacedFontFamily = Platform.OS === 'ios' ? 'Courier' : 'monospace';
const styles = StyleSheet.create({
    instructions: {
        backgroundColor: '#ffffff',
        margin: 8,
        padding: 8,
    },
    instructionStep: {
        fontSize: 18,
    },
    monospace: {
        fontFamily: monospacedFontFamily,
        fontSize: 16,
        marginVertical: 4,
        marginHorizontal: 8,
    },
});

export default styles;
