import { AppRegistry } from 'react-native';
import App from './App';
import mobileTenantConfig from '../src/MobileTenantConfig';
import { widgetName } from './webConfig';

const appName = mobileTenantConfig.projectName;

// appRoot must match the format expected by ESSP. For more info, see
// https://code.amazon.com/packages/EmployeeSelfServicePortal/blobs/2a5353380c16e89b486737d6e0e898e1c8928741/--/rails-root/app/helpers/app_data_helper.rb#L4,L41
const appRoot = `${widgetName}-root`;

const mountApp = () => {
    AppRegistry.registerComponent(appName, () => App);

    const rootTag = document.getElementById(appRoot);
    AppRegistry.runApplication(appName, {
        initialProps: { appName, rootTag },
        rootTag,
    });
};

document.addEventListener('DOMContentLoaded', mountApp);
